import React, { useState, useEffect } from "react";
import moment from "moment";
import { EMPLOYEE,ADDLAUNDRYVARIATIONS ,LAUNDRYVARIATIONS} from "../../constants/paths";
import {
  postData,
  getDataWithToken,
  deleteData,
  putData,
} from "../../network/ApiService";
import Sidebar from "../../parts/SIdeBar";
import { PATH } from "../../constants/paths";
import { Link } from "react-router-dom";
import { returnTime } from "../../utils/dateFormat";

import {
  Form,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  TimePicker,
  message,
  Table,
  Select,
  Row,
  Col,
  Modal,
  Space,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ExclamationCircleOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const ProductVariant = () => {
  const [collapsed, setCollapse] = useState(false);
  const [in_date, setInDate] = useState("");
  const [out_date, setOutDate] = useState("");
  const [id_employee, setIdEmployee] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [visible_modal, setVisibleModal] = useState(false);
  const [add_status_modal, setAddStatusModal] = useState(true);

  const [form] = Form.useForm();
  const { confirm } = Modal;
  const format = "HH:mm";
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Variation Name",
      dataIndex: "variationName",
      key: "variationName",
    },
    {
      title: "Variation Code",
      dataIndex: "variationCode",
      key: "variationCode",
    },
    {
      title: "Variation Short",
      dataIndex: "variationShort",
      key: "variationShort",
    },

    {
      title: "Variation Long",
      dataIndex: "variationLong",
      key: "variationLong",
    },

    {
      title: "Action",
      dataIndex: "",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showModal(false, record)} type="primary">
            Edit
          </Button>

          <Button onClick={() => showConfirm(record)} type="danger">
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getDataWithToken(LAUNDRYVARIATIONS, null)
      .then((res) => {
        console.log("res " + res);
        console.log("res= " + res.employee);
        var clearData = [];
        res.laundryvariation.forEach((element) => {
          if (element.deletedAt == null) {
            clearData.push(element);
          }
        });
        setDataSource(clearData);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error");
      });
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {};

  const showModal = (addStatus, data) => {
    console.log("showModal: ", data);

    if (!addStatus) {
      setIdEmployee(data.id);
      form.setFieldsValue(data);
    }
    setAddStatusModal(addStatus);
    setVisibleModal(true);
  };

  const onOkModal = () => {
    form.submit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    var currentDate = moment().format("YYYY-MM-DD");
    console.log("values: ", values);
    if (!add_status_modal) {
      values.id = id_employee;
      const res = await putData(EMPLOYEE, values)
        .then((response) => {
          console.log("response: ", response);
          message.success("success to save data");
          setVisibleModal(false);
          getData();
        })
        .catch((error) => {
          message.error("error to save data " + error);

          console.log("error: ", error);
        });
    } else {
      const res = await postData(ADDLAUNDRYVARIATIONS, values)
        .then((response) => {
          console.log("response: ", response);
          message.success("success to save data");
          setVisibleModal(false);
        })
        .catch((error) => {
          message.error("error to save data " + error);

          console.log("error: ", error);
        });
    }

    // window.location.href = '/home'
  };

  const toggle = () => {
    setCollapse(!collapsed);
  };

  const deleteEmployee = async (id) => {
    deleteData(EMPLOYEE + "/" + id)
      .then((res) => {
        message.success("success to delete data");
        getData();
      })
      .catch((err) => {
        message.error("error to delete data " + err);
      });
  };

  const showConfirm = (data) => {
    console.log("showConfirm: ", data);
    setTimeout(() => {
      confirm({
        title: "Do you want to delete this employee? \n" + data.accountname,
        icon: <ExclamationCircleOutlined />,
        okType: "danger",
        onOk() {
          console.log("OK");
          deleteEmployee(data.id);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }, 500);
  };

  const modalAddForm = () => {
    return (
      <Modal
        title="Form Product Variations"
        centered
        visible={visible_modal}
        onOk={() => onOkModal()}
        onCancel={() => setVisibleModal(false)}
        width={1000}
        okText="Save"
        cancelText="Cancel"
      >
        <Form
          name="variation_form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <Form.Item
            label="Variation Name"
            name="variationName"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Variation Code"
            name="variationCode"
            rules={[{ required: true, message: "Please input your item code!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Variation Short"
            name="variationShort"
            rules={[{ required: true, message: "Please input your item short!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Variation Long"
            name="variationLong"
            rules={[{ required: true, message: "Please input your itemUOM1!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Title level={3} style={{ color: "#ffffff" }}>
            Loundry
          </Title>
        </div>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
        {modalAddForm()}
          <Row>
            <Col span={20}>
              <Title
                level={3}
                style={{ color: "#000000", marginBottom: "40px" }}
              >
                List Product Variant
              </Title>
            </Col>

            <Col>
              <Button
                onClick={() => showModal(true)}
                type="primary"
                htmlType="submit"
              >
                Tambah
              </Button>
            </Col>
          </Row>
          <Table dataSource={dataSource} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ProductVariant;
