import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { PATH } from "../../constants/paths";
import { postDataWithoutToken } from "../../network/ApiService";
import { LOGIN } from "../../constants/paths";

const Login = () => {
  const [loading, setloading] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    await postDataWithoutToken(LOGIN, values)
      .then((res) => {
        console.log("res " + res.user.username);
        if (res.token != null) {
          message.success("login success");
          localStorage.setItem("token", res.token);
          localStorage.setItem("username", JSON.stringify(res.user.username));
          localStorage.setItem("email", JSON.stringify(res.user.email));

          window.location.href = "/home";
        }
      })
      .catch((err) => {
        message.error("login failed");
      });
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="title">
        <h1>Login</h1>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your Username!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember mee</Checkbox>
        </Form.Item>

        <a className="login-form-forgot" href="">
          Forgot password
        </a>
      </Form.Item>

      <Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Log in
        </Button>
        Or <a href={PATH.REGISTER}>register now!</a>
      </Form.Item>
    </Form>
  );
};

export default Login;
