import React, { useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { PATH } from "../constants/paths";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  AuditOutlined,
  LogoutOutlined,
  BoxPlotOutlined,
} from "@ant-design/icons";
const Sidebar = (props) => {
  return (
    <Menu theme="dark" mode="inline">
      <Menu.Item key="1" icon={<BoxPlotOutlined />}>
        <NavLink to={PATH.PRODUCT}>Product Item</NavLink>
      </Menu.Item>

      <Menu.Item key="2" icon={<BoxPlotOutlined />}>
        <NavLink to={PATH.PRODUCTVARIANT}>Product Variant</NavLink>
      </Menu.Item>

      <Menu.Item key="3" icon={<BoxPlotOutlined />}>
        <NavLink to={PATH.PRODUCTITEMDETAIL}>Product Item Detail</NavLink>
      </Menu.Item>

      <Menu.Item key="4" icon={<UserOutlined />}>
        <NavLink to={PATH.USERS}>User</NavLink>
      </Menu.Item>
      <Menu.Item key="5" icon={<VideoCameraOutlined />}>
      <NavLink to={PATH.EMPLOYEES}>Employees</NavLink>
        
      </Menu.Item>
      <Menu.Item key="6" icon={<UploadOutlined />}>
      <NavLink to={PATH.VENDOR}>Vendor</NavLink>
      </Menu.Item>

      <Menu.Item key="7" icon={<MenuFoldOutlined />}>
        <NavLink to={PATH.SUBMIT_ATTENDANCE}>Attendance</NavLink>
      </Menu.Item>
      
      <Menu.Item key="8" icon={<AuditOutlined />}>
        <NavLink to={PATH.ATTENDANCE}>Attendance Plan</NavLink>
      </Menu.Item>

      <Menu.Item key="9" icon={<LogoutOutlined />}>
        <NavLink  to="/">Logout</NavLink>
      </Menu.Item>
    </Menu>
  );
};
export default Sidebar;
