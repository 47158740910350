export const PATH = {
    HOME: "/home",
    REGISTER: "/register",
    LOGIN: "/login",
    SUBMIT_ATTENDANCE: "/submitAttendance",
    ATTENDANCE: "/attendance",
    ADDATTENDANCE: "/addAttendancePlane",
    EDITATTENDANCE: "/editAttendancePlane",
    HISTORYATTENDANCE: "/historyAttendancePlane",
    EMPLOYEES: "/employees",
    USERS: "/users",
    VENDOR: "/vendor",
    PRODUCT: "/product",
    PRODUCTVARIANT: "/productVariant",
    PRODUCTITEMDETAIL: "/productItemDetail",
  }

// export const BASE_URL = "http://52.74.185.0:3001"
export const BASE_URL = "https://dev.dressup.id"
export const LOGIN = "/login"
export const USERS = "/users"
export const CREATEATTENDANCEPLANE = "/createattendanceplan"
export const EDITATTENDANCEPLANE = "/editattendanceplan"
export const ATTENDANCEPLANELINE = "/createattendanceplanline"
export const ALL_ATTENDANCE_PLANE = "/attendanceplans"
export const ATTENDANCE_PLANE = "/attendanceplan"
export const EMPLOYEES = "/employees"
export const EMPLOYEE = "/employee"
export const CHECKINOUT = "/checkinout"
export const COMPANIES = "/companies"
export const COMPANY = "/company"
export const ISCHECKINOUT = "/isCheckedInOut"
export const ATTENDANCEHISTORY = "/attendancehistory"
export const LAUNDRYITEMS = "/laundryitems"
export const LAUNDRYITEMDETAIL = "/laundryitemdetail"
export const ADDLAUNDRYITEM = "/laundryitem"
export const LAUNDRYVARIATIONS = "/laundryvariations"
export const ADDLAUNDRYVARIATIONS = "/laundryvariation"

