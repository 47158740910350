import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
  CREATEATTENDANCEPLANE,
  EDITATTENDANCEPLANE,
  EMPLOYEES,
  ATTENDANCE_PLANE,
} from "../../constants/paths";
import { putData, postData, getDataWithToken } from "../../network/ApiService";
import Sidebar from "../../parts/SIdeBar";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

import {
  Form,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  TimePicker,
  message,
  Table,
  Row,
  Col,
  Select,
  Space,
  DatePicker,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";
const { Text, Link } = Typography;
const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const EditAttendancePlan = () => {
  var currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

  const [collapsed, setCollapse] = useState(false);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [btn_loading, setBtnLoading] = useState(false);
  const [isRenderList, setIsRenderList] = useState(true);
  const [attendance_in, setAttendanceIn] = useState(currentDate);
  const [attendance_out, setAttendanceOut] = useState(currentDate);
  const [employees, setEmployees] = useState([]);
  const [employe_name, setEmployeName] = useState("");
  const refButton = useRef(null);

  const format = "HH:mm";
  const dateFormat = "YYYY-MM-DD";
  const { state } = useLocation();
  const [form] = Form.useForm();

  useEffect(() => {
    console.log(state);
    getEmployees();
    setFormData();
  }, []);

  const setFormData = () => {
    var periodstart = moment().format("YYYY-MM-DD");
    console.log(periodstart);

    form.setFieldsValue({
      employeeId: state.employeeId,
      attendanceplanname: "",
      periodstart: moment(state.periodstart, "YYYY-MM-DD"),
      periodend: moment(state.periodend, "YYYY-MM-DD"),
      detail: [],
    });
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {};

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    setBtnLoading(true);
    var currentDateNew = moment().format("YYYY-MM-DD");
    var dataDetail = values.detail;
    for (let i = 0; i < dataDetail.length; i++) {
      var date = moment(dataDetail[i].date).format(dateFormat);
      var attendance_in = moment(dataDetail[i].attendance_in).format("HH:mm");
      var attendance_out = moment(dataDetail[i].attendance_out).format("HH:mm");
      dataDetail[i].date = date;
      dataDetail[i].attendance_in = attendance_in
      dataDetail[i].attendance_out = attendance_out
    }

    console.log("values: ", values);

    const res = await putData(EDITATTENDANCEPLANE + "/" + state.id, values)
      .then((response) => {
        message.success("success to save data");
        setBtnLoading(false);
      })
      .catch((error) => {
        message.error("error to save data");
      });

    // window.location.href = '/home'
  };

  const getEmployees = async () => {
    var values = {};
    const res = await getDataWithToken(EMPLOYEES + "?name=", values)
      .then((response) => {
        console.log("getEmployees: ", response);
        setEmployees(response.employee != null ? response.employee : []);
        form.setFieldsValue({
          employeeId: state.employeeId,
          attendanceplanname: "",
          periodstart: moment(state.periodstart, "YYYY-MM-DD"),
          periodend: moment(state.periodend, "YYYY-MM-DD"),
          detail: [],
        });
        getAttendancePlane();
      })
      .catch((error) => {
        console.log("getEmployees error: ", error);
      });
  };

  const getAttendancePlane = async () => {
    var values = {};
    const res = await getDataWithToken(
      ATTENDANCE_PLANE + "/" + state.id,
      values
    )
      .then((response) => {
        console.log("getAttendancePlane response: ", response);
        var dataDetails = [];
        var deatails = [];
        dataDetails = response.attendanceplan.attendanceplanline;
        dataDetails.forEach((element) => {
          var detail = {
            id: element.id,
            attendanceplanId: element.attendanceplanId,
            date: moment(element.date, dateFormat),
            attendance_in: moment(element.attendance_in, "HH:mm"),
            attendance_out: moment(element.attendance_out, "HH:mm"),
            shiftcode: element.shiftcode,
            locationtype: element.locationtype,
            locationid: element.locationid,
          };
          deatails.push(detail);
        });
        form.setFieldsValue({
          employeeId: state.employeeId,
          attendanceplanname: "",
          periodstart: moment(state.periodstart, "YYYY-MM-DD"),
          periodend: moment(state.periodend, "YYYY-MM-DD"),
          detail: deatails,
        });
      })
      .catch((error) => {
        console.log("getAttendancePlane error: ", error);
      });
  };

  const toggle = () => {
    setCollapse(!collapsed);
  };

  const onChangeTimeIn = (time, timeString) => {
    // setInDate(timeString);
  };
  const onChangeTimeOut = (time, timeString) => {
    // setOutDate(timeString);
  };

  const onChangeDateStart = async (date, dateString) => {
    await setStartDate(date);
    if (end_date !== "") {
      setIsRenderList(false);
      var a = moment(date, "YYYY-MM-DD");
      var b = moment(end_date, "YYYY-MM-DD");
      var c = 0;
      c = b.diff(a, "days");

      if (c > 0) {
        for (let index = 0; index < c; index++) {
          refButton.current.click();
        }
      }
    }
    setIsRenderList(true);
  };

  const onChangeDateEnd = async (date, dateString) => {
    await setEndDate(date);
    if (start_date !== "") {
      var a = moment(start_date, "YYYY-MM-DD");
      var b = moment(date, "YYYY-MM-DD");
      var c = 0;
      c = b.diff(a, "days");

      if (c > 0) {
        for (let index = 0; index < c; index++) {
          refButton.current.click();
        }
      }
    }
    setIsRenderList(true);
  };

  const onOkDate = (date) => {
    console.log("onOkdate " + date);
  };

  const handleAddForm = (f) => {
    console.log("f: ", f);
  };

  const onSelectChange = (value) => {
    setEmployeName(value);
    console.log("value: ", value);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Title level={3} style={{ color: "#ffffff" }}>
            Loundry
          </Title>
        </div>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Title level={3} style={{ color: "#000000", marginBottom: "40px" }}>
            Edit Attendance Plan
          </Title>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 20 }}
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Periode Start"
                  name="periodstart"
                  rules={[{ required: true, message: "Please input time " }]}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    value={start_date}
                    onChange={onChangeDateStart}
                    onOk={(date) => onOkDate(date)}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Periode End"
                  name="periodend"
                  rules={[
                    { required: true, message: "Please input your time " },
                  ]}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    value={end_date}
                    onChange={onChangeDateEnd}
                    onOk={(date) => onOkDate(date)}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col span={12}>
              <Form.Item
                label="Employe Name"
                name="employeeId"
                rules={[{ required: true, message: "Please input time " }]}
              >
                <Select
                  showSearch
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={onSelectChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {employees.map((employee) => (
                    <Option value={employee.id}>{employee.employeeName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Title level={4} style={{ color: "#000000" }}>
              Attendance Plan Inline
            </Title>
            <Form.List name="detail">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        hidden={true}
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 24 }}
                        label="id"
                        {...restField}
                        name={[name, "id"]}
                        rules={[{ required: false, message: "Missing date" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 24 }}
                        label="Date"
                        {...restField}
                        name={[name, "date"]}
                        rules={[{ required: true, message: "Missing date" }]}
                      >
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 12 }}
                        label="Shift Code"
                        {...restField}
                        name={[name, "shiftcode"]}
                        rules={[
                          { required: true, message: "Missing shift code" },
                        ]}
                      >
                        {/* <Text>Shift Code</Text> */}

                        <Input placeholder="Shift Code" />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label="Attendance In"
                        {...restField}
                        name={[name, "attendance_in"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Attendance In",
                          },
                        ]}
                      >
                        <TimePicker
                          format={"HH:mm"}
                          defaultValue={moment("07:00", "HH:mm")}
                          onChange={onChange}
                          defaultOpenValue={moment("00:00", "HH:mm")}
                        />
                      </Form.Item>

                      <Form.Item
                        labelCol={{ span: 24 }}
                        label="Attendance Out"
                        {...restField}
                        name={[name, "attendance_out"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Attendance Out",
                          },
                        ]}
                      >
                        <TimePicker
                          defaultValue={moment("16:00", "HH:mm")}
                          format={"HH:mm"}
                          onChange={onChange}
                          defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                        />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label="Location Type"
                        {...restField}
                        name={[name, "locationtype"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing location type",
                          },
                        ]}
                      >
                        <Input placeholder="Location Type" />
                      </Form.Item>
                      <Form.Item
                        initialValue={"01"}
                        hidden={true}
                        labelCol={{ span: 24 }}
                        label="Location Id"
                        {...restField}
                        name={[name, "locationid"]}
                        rules={[
                          {
                            required: false,
                            message: "Missing location type",
                          },
                        ]}
                      >
                        <Input value={"01"} placeholder="Location Type" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item wrapperCol={{ offset: 0, span: 10 }}>
                    <Button
                      ref={refButton}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item wrapperCol={{ offset: 0, span: 10 }}>
              <Button type="primary" htmlType="submit" loading={btn_loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditAttendancePlan;
