import React, { useState, useEffect } from "react";
import moment from "moment";
import { CHECKINOUT, ISCHECKINOUT ,ATTENDANCEHISTORY} from "../../constants/paths";
import {
  getDataWithToken,
  postData,
} from "../../network/ApiService";
import Sidebar from "../../parts/SIdeBar";
import { PATH } from "../../constants/paths";
import { Link } from "react-router-dom";
import { returnTime } from "../../utils/dateFormat";
import Clock from "react-live-clock";

import {
  Form,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  TimePicker,
  message,
  Table,
  Select,
  Row,
  Col,
  Space,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const SubmiAttendance = () => {
  const [collapsed, setCollapse] = useState(false);
  const [allowCheckIn, setAllowCheckIn] = useState(false);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [time, setTime] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const format = "HH:mm A";

  useEffect(() => {
    //set time
    setTime(moment().format(format));
    getStatus();
    getPosition();
    getAttendanceHistory();
  }, []);

  const getAttendanceHistory = async () => {
    await getDataWithToken(ATTENDANCEHISTORY+'?startDate=2022-01-01&endDate=2022-12-12', null)
      .then((res) => {
        console.log("res " + res);
        console.log("res= " + res);
        setDataSource(res.attendance);
      })
      .catch((err) => {
        console.log(err);
      }
      );
  }

  const getStatus = () => {
    var currentDate = moment().format("YYYY-MM-DD");

    var values = {};
    getDataWithToken(
      ISCHECKINOUT + "?date=" + currentDate + "&enddate=" + currentDate,
      values
    )
      .then((res) => {
        console.log("res " + res.allowCheckIn);
        if (res.allowCheckIn) {
          setAllowCheckIn(true);
        } else {
          setAllowCheckIn(false);
        }
      })
      .catch((err) => {
        console.log("res err" + err);
      });
  };

  const getPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        setLat(position.coords.latitude);
        setLon(position.coords.longitude);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const postCHECKIN = async () => {
    var data = {
      employeeId: 1,
      checktype: "IN",
      checklon: lon,
      checklat: lat,
      inactive_at: null,
    };
    await postData(CHECKINOUT, data)
      .then((res) => {
        console.log("res " + res);
        console.log("res= " + res.data);
        message.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postCHECKOUT = async () => {
    var data = {
      employeeId: 1,
      checktype: "OUT",
      checklon: lon,
      checklat: lat,
      inactive_at: null,
    };
    await postData(CHECKINOUT, data)
      .then((res) => {
        console.log("res " + res);
        console.log("res= " + res.data);
        message.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggle = () => {
    setCollapse(!collapsed);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Title level={3} style={{ color: "#ffffff" }}>
            Loundry
          </Title>
        </div>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Row>
            <Col span={20}>
              <Title
                level={3}
                style={{ color: "#000000", marginBottom: "10px" }}
              >
                Live Attendance
              </Title>
            </Col>
          </Row>

          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Clock
              style={{ fontSize: "48px" }}
              format={"HH:mm:ss"}
              ticking={true}
              timezone={"Asia/Bangkok"}
            />
          </Space>

          <div
            className="site-card-wrapper"
            style={{
              backgroundColor: "#e8e8ed",
              padding: "50px",
              borderRadius: "10px",
            }}
          >
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Title level={4}> Schedule {moment().format("DD-MM-YYYY")}</Title>
            </Space>

            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Title level={3}>08:00 AM - 05:00 PM</Title>
            </Space>

            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              {allowCheckIn && (
                <Button
                  onClick={() => {
                    postCHECKIN();
                  }}
                  type="primary"
                  style={{  width: "100px" }}
                >
                  Clock In
                </Button>
              )}

              {!allowCheckIn && (
                <Button
                  onClick={() => {
                    postCHECKOUT();
                  }}
                  type="primary"
                  style={{ width: "100px" }}
                >
                  Clock Out
                </Button>
              )}
            </Space>
          </div>

          <Table style={{marginTop: '10px'}} dataSource={dataSource} columns={columns} />

        </Content>
      </Layout>
    </Layout>
  );
};

const columns = [
  {
    title: "No",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "employeeId",
    key: "employeeId",
  },
  {
    title: "Check In Type",
    dataIndex: "checktype",
    key: "checktype",
    // render: (date) => returnTime(date), // render
  },
  {
    title: "checktimestamp",
    dataIndex: "checktimestamp",
    key: "checktimestamp",
  },
];

export default SubmiAttendance;
