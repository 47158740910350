import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  ATTENDANCEPLANELINE,
  ATTENDANCEHISTORY,
} from "../../constants/paths";
import { postDataWithoutToken, getDataWithToken } from "../../network/ApiService";
import Sidebar from "../../parts/SIdeBar";
import { useLocation } from "react-router-dom";
import { PATH } from "../../constants/paths";
import { Link } from "react-router-dom";
import { returnTime } from "../../utils/dateFormat";

import {
  Form,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  Space,
  message,
  Table,
  Select,
  Row,
  Col,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const HistoryAttendancePlane = () => {
  const [collapsed, setCollapse] = useState(false);
  const [in_date, setInDate] = useState("");
  const [out_date, setOutDate] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const { state } = useLocation();


  const format = "HH:mm";

  useEffect(() => {
    getAttendanceHistory();
  }, []);

  const getAttendanceHistory = async () => {
    await getDataWithToken(ATTENDANCEHISTORY+'?startDate=2022-01-01&endDate=2022-12-12&employeeid='+state.employeeId, null)
    .then((res) => {
      console.log("res " + res);
      console.log("res= " + res);
      setDataSource(res.attendance);
    })
    .catch((err) => {
      console.log(err);
    }
    );
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {};

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    var currentDate = moment().format("YYYY-MM-DD");
    values.date = currentDate;
    values.attendanceplanid = 1;
    values.shiftcode = "P";
    values.attendance_in = in_date;
    values.attendance_out = out_date;

    console.log("values: ", values);

    const res = await postDataWithoutToken(ATTENDANCEPLANELINE, values)
      .then((response) => {
        console.log("response: ", response);
        message.success("success to save data");
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    // window.location.href = '/home'
  };

  const toggle = () => {
    setCollapse(!collapsed);
  };

  const onChangeTimeIn = (time, timeString) => {
    setInDate(timeString);
  };
  const onChangeTimeOut = (time, timeString) => {
    setOutDate(timeString);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Title level={3} style={{ color: "#ffffff" }}>
            Loundry
          </Title>
        </div>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Row>
            <Col span={20}>
              <Title
                level={3}
                style={{ color: "#000000", marginBottom: "40px" }}
              >
                History Attendance Plan
              </Title>
            </Col>
          </Row>
          <Table dataSource={dataSource} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

// const dataSource = [
//   {
//     key: "1",
//     date: "2020-01-01",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "2",
//     date: "2020-01-02",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "3",
//     date: "2020-01-01",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "4",
//     date: "2020-01-02",
//     in: "08:00",
//     out: "17:00",
//   },
// ];

const columns = [
  {
    title: "No",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "employeeId",
    key: "employeeId",
  },
  {
    title: "Check In Type",
    dataIndex: "checktype",
    key: "checktype",
    // render: (date) => returnTime(date), // render
  },
  {
    title: "checktimestamp",
    dataIndex: "checktimestamp",
    key: "checktimestamp",
  },
];
export default HistoryAttendancePlane;
