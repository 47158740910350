import React, { useEffect, useState } from "react";
import { Layout, Menu, Table, Tag, Typography } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { getDataWithToken} from "../../network/ApiService";
import { utcToLocal } from "../../helper/helper";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;


const Company = () => {

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Code',
      dataIndex: 'companyCode',
      key: 'companyCode',
    },
    {
      title: 'Initial',
      dataIndex: 'companyInitial',
      key: 'companyInitial',
    },
    {
      title: 'Company Type',
      dataIndex: 'companyType',
      key: 'companyType',
    },

    {
      title: 'Status',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (val) => val ? <Tag color='red'>Inactive</Tag> : <Tag color='green'>Active</Tag>
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (value) => <span>{utcToLocal(value)}</span>
    // },
  ]

  const [collapsed, setCollapse] = useState(false);
  const [company, setcompany] = useState([])
  const [loading, setloading] = useState(false)
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const res = await getDataWithToken('/companies')
    console.log(res.company);
    setcompany(res.company)
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" >
          <Title level={3} style={{ color: "#ffffff" }}>Loundry</Title>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<UserOutlined />}>
            User
          </Menu.Item>
          <Menu.Item key="2" icon={<VideoCameraOutlined />}>
            Employe
          </Menu.Item>
          <Menu.Item key="3" icon={<UploadOutlined />}>
            Vendor
          </Menu.Item>
          <Menu.Item key="3" icon={<UploadOutlined />}>
            Company
          </Menu.Item>

        </Menu>
      </Sider>
      <Layout className="site-layout">

        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          Company

          <Table
            columns={tableColumns}
            loading={loading}

            dataSource={company} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Company;
