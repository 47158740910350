import React, { useState, useEffect } from "react";
import moment from "moment";
import { COMPANIES, COMPANY } from "../../constants/paths";
import {
  postData,
  getDataWithToken,
  deleteData,
  putData,
} from "../../network/ApiService";
import Sidebar from "../../parts/SIdeBar";
import { PATH } from "../../constants/paths";
import { Link } from "react-router-dom";
import { returnTime } from "../../utils/dateFormat";

import {
  Form,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  TimePicker,
  message,
  Table,
  Select,
  Row,
  Col,
  Modal,
  Space,
  DatePicker,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ExclamationCircleOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const Vendor = () => {
  const [collapsed, setCollapse] = useState(false);
  const [in_date, setInDate] = useState("");
  const [out_date, setOutDate] = useState("");
  const [id_company, setIdCompany] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [visible_modal, setVisibleModal] = useState(false);
  const [add_status_modal, setAddStatusModal] = useState(true);

  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const { confirm } = Modal;
  const format = "HH:mm";
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "employeeName",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Type",
      dataIndex: "companyType",
      key: "companyType",
    },
    {
      title: "Tax Reg.",
      dataIndex: "taxreg",
      key: "taxreg",
    },
    {
      title: "Address",
      dataIndex: "companyListedAddress",
      key: "companyListedAddress",
    },
    {
      title: "Action",
      dataIndex: "",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showModal(false, record)} type="primary">
            Edit
          </Button>

          <Button onClick={() => showConfirm(record)} type="danger">
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getAllCompany();
  }, []);

  const getAllCompany = async () => {
    await getDataWithToken(COMPANIES, null)
      .then((res) => {
        console.log("res " + res);
        if (res.company != null) {
          var clearData = [];
          for (let i = 0; i < res.company.length; i++) {
            if(res.company[i].deletedAt == null){
              clearData.push(res.company[i])
            }
          }
        }

        setDataSource(clearData);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error");
      });
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {};

  const showModal = (addStatus, data) => {
    console.log("showModal: ", data);
    console.log("showModal: ", addStatus);

    if (!addStatus) {
      setIdCompany(data.id);
      data.incorporateDate = moment(data.incorporateDate, "YYYY-MM-DD");
      form.setFieldsValue(data);
    }
    setAddStatusModal(addStatus);
    setVisibleModal(true);
  };

  const onOkModal = () => {
    form.submit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    var currentDate = moment().format("YYYY-MM-DD");
    console.log("values: ", values);
    if (!add_status_modal) {
      values.id = id_company;

      const res = await putData(COMPANY, values)
        .then((response) => {
          console.log("response: ", response);
          message.success("success to save data");
          setVisibleModal(false);
          getAllCompany();
        })
        .catch((error) => {
          message.error("error to save data " + error);

          console.log("error: ", error);
        });
    } else {
      values.updateby = 1;
      values.inactive_at = null;
      const res = await postData(COMPANY, values,"")
        .then((response) => {
          console.log("response: ", response);
          message.success("success to save data");
          setVisibleModal(false);
          getAllCompany();
        })
        .catch((error) => {
          message.error("error to save data " + error);

          console.log("error: ", error);
        });
    }

    // window.location.href = '/home'
  };

  const toggle = () => {
    setCollapse(!collapsed);
  };

  const deleteEmployee = async (id) => {
    deleteData(COMPANY + "/" + id)
      .then((res) => {
        message.success("success to delete data");
        getAllCompany();
      })
      .catch((err) => {
        message.error("error to delete data " + err);
      });
  };

  const showConfirm = (data) => {
    console.log("showConfirm: ", data);
    setTimeout(() => {
      confirm({
        title: "Do you want to delete this employee? \n" + data.accountname,
        icon: <ExclamationCircleOutlined />,
        okType: "danger",
        onOk() {
          console.log("OK");
          deleteEmployee(data.id);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }, 500);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Title level={3} style={{ color: "#ffffff" }}>
            Loundry
          </Title>
        </div>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Modal
            title="Form Vendor"
            centered
            visible={visible_modal}
            onOk={() => onOkModal()}
            onCancel={() => setVisibleModal(false)}
            width={1000}
            okText="Save"
            cancelText="Cancel"
          >
            <Form
              name="employee"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              form={form}
            >
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please input your company name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Company Code"
                name="companyCode"
                rules={[
                  {
                    required: true,
                    message: "Please input your company code!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Company Initial"
                name="companyInitial"
                rules={[
                  { required: true, message: "Please input your Initial!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Company Type"
                name="companyType"
                rules={[
                  {
                    required: true,
                    message: "Please input your Company Type!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Address"
                name="companyListedAddress"
                rules={[
                  { required: true, message: "Please input your address!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Incorporate Date"
                name="incorporateDate"
                rules={[
                  {
                    required: true,
                    message: "Please input your incorporate date!",
                  },
                ]}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>

              <Form.Item
                label="Tagreg"
                name="taxreg"
                rules={[
                  { required: true, message: "Please input your tagreg!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
          <Row>
            <Col span={20}>
              <Title
                level={3}
                style={{ color: "#000000", marginBottom: "40px" }}
              >
                List Vendor
              </Title>
            </Col>

            <Col>
              <Button
                onClick={() => showModal(true)}
                type="primary"
                htmlType="submit"
              >
                Tambah
              </Button>
            </Col>
          </Row>
          <Table dataSource={dataSource} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

// const dataSource = [
//   {
//     key: "1",
//     date: "2020-01-01",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "2",
//     date: "2020-01-02",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "3",
//     date: "2020-01-01",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "4",
//     date: "2020-01-02",
//     in: "08:00",
//     out: "17:00",
//   },
// ];

export default Vendor;
