import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../constants/paths";

export const postData = async (url, data, token) => {
  token = localStorage.getItem("token");
  console.log("TOKEN", token);
  try {
    let res = await axios.post(BASE_URL + url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error("error postData", error);
  }
};

export const postDataWithoutToken = async (url, data) => {
  try {
    let res = await axios.post(BASE_URL + url, data);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
  }
};

export const deleteData = async (url) => {
  try {
    let res = await axios.delete(BASE_URL + url);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
  }
};

export const putData = async (url, data) => {
  var token = localStorage.getItem("token");

  try {
    let res = await axios.put(BASE_URL + url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    message.error(error.response.data);
  }
};

export const getDataWithToken = async (url, params) => {
  var token = localStorage.getItem("token");
  try {
    let res = await axios.get(BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    message.error(error.response.data);
  }
};

export const getDataWithoutToken = async (url, params) => {
  try {
    let res = await axios.get(BASE_URL + url);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
  }
};
