import React, { useState } from "react";
import moment from "moment";
import { ATTENDANCEPLANELINE } from "../../constants/paths";
import { postDataWithoutToken } from "../../network/ApiService";
import Sidebar from "../../parts/SIdeBar";

import {
  Form,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  TimePicker,
  message,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const Home = () => {
  const [collapsed, setCollapse] = useState(false);
  const [in_date, setInDate] = useState("");
  const [out_date, setOutDate] = useState("");

  const format = "HH:mm";

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    var currentDate = moment().format("YYYY-MM-DD");
    values.date = currentDate;
    values.attendanceplanid = 1;
    values.shiftcode = "P";
    values.attendance_in = in_date;
    values.attendance_out = out_date;

    console.log("values: ", values);

    const res = await postDataWithoutToken(ATTENDANCEPLANELINE, values)
      .then((response) => {
        console.log("response: ", response);
        message.success("login success");
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    // window.location.href = '/home'
  };

  const toggle = () => {
    setCollapse(!collapsed);
  };

  const onChangeTimeIn = (time, timeString) => {
    setInDate(timeString);
  };
  const onChangeTimeOut = (time, timeString) => {
    setOutDate(timeString);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Title level={3} style={{ color: "#ffffff" }}>
            Loundry
          </Title>
        </div>
       <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Title level={3} style={{ color: "#000000", marginBottom: "40px" }}>
            Content
          </Title>
         
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
