import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Login from "./pages/login/Login"
import Home from "./pages/home/Home"
import Attendance from "./pages/attendance/index"
import AddAttendancePlan from './pages/attendance/AddAttendancePlan';
import EditAttendancePlan from './pages/attendance/EditAttendancePlan';
import SubmiAttendance from './pages/submitAttendance';
import Employees from './pages/employees';
import Product from './pages/product';
import ProductItemDetail from './pages/product/productItemDetail';
import ProductVariant from './pages/product/productVariant';
import Vendor from './pages/vendor';
import User from './pages/user';
import HistoryAttendancePlan from './pages/attendance/HistoryAttendancePlan';
import { PATH } from "./constants/paths"
import Company from './pages/home/Company';

// function App() {
//   return(
//     <div className="App">
//       <h1>Welcome to React Router!</h1>
//       <Routes>
//         <Route path={PATH.LOGIN} element={<Home />} />
//       </Routes>
//     </div>
//   );
// }

function App() {
  return (
    <Routes>
      <Route path={PATH.HOME} element={<Home />} />
      <Route path='company' element={<Company />} />
      <Route path="about" element={<About />} />
      <Route path={PATH.LOGIN} element={<Login />} />
      <Route path={PATH.SUBMIT_ATTENDANCE} element={<SubmiAttendance />} />
      <Route path={PATH.ATTENDANCE} element={<Attendance />} />
      <Route path={PATH.ADDATTENDANCE} element={<AddAttendancePlan />} />
      <Route path={PATH.EDITATTENDANCE} element={<EditAttendancePlan />} />
      <Route path={PATH.EMPLOYEES} element={<Employees />} />
      <Route path={PATH.USERS} element={<User />} />
      <Route path={PATH.VENDOR} element={<Vendor />} />
      <Route path={PATH.HISTORYATTENDANCE} element={<HistoryAttendancePlan />} />
      <Route path={PATH.PRODUCT} element={<Product />} />
      <Route path={PATH.PRODUCTVARIANT} element={<ProductVariant />} />
      <Route path={PATH.PRODUCTITEMDETAIL} element={<ProductItemDetail />} />
      <Route path="/" element={<Login />} />
    </Routes>
  );
}


function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>
          That feels like an existential question, don't you
          think?
        </p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}

export default App;
