import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  ATTENDANCEPLANELINE,
  ALL_ATTENDANCE_PLANE,
} from "../../constants/paths";
import { postDataWithoutToken, postData } from "../../network/ApiService";
import Sidebar from "../../parts/SIdeBar";
import { PATH } from "../../constants/paths";
import { Link } from "react-router-dom";
import { returnTime } from "../../utils/dateFormat";

import {
  Form,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  Space,
  message,
  Table,
  Select,
  Row,
  Col,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const Home = () => {
  const [collapsed, setCollapse] = useState(false);
  const [in_date, setInDate] = useState("");
  const [out_date, setOutDate] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const format = "HH:mm";

  useEffect(() => {
    getAllAttendanceOlane();
  }, []);

  const getAllAttendanceOlane = async () => {
    await postData(ALL_ATTENDANCE_PLANE, null)
      .then((res) => {
        console.log("res " + res);
        console.log("res= " + res.data);
        setDataSource(res.attendanceplan);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {};

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    var currentDate = moment().format("YYYY-MM-DD");
    values.date = currentDate;
    values.attendanceplanid = 1;
    values.shiftcode = "P";
    values.attendance_in = in_date;
    values.attendance_out = out_date;

    console.log("values: ", values);

    const res = await postDataWithoutToken(ATTENDANCEPLANELINE, values)
      .then((response) => {
        console.log("response: ", response);
        message.success("success to save data");
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    // window.location.href = '/home'
  };

  const toggle = () => {
    setCollapse(!collapsed);
  };

  const onChangeTimeIn = (time, timeString) => {
    setInDate(timeString);
  };
  const onChangeTimeOut = (time, timeString) => {
    setOutDate(timeString);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Title level={3} style={{ color: "#ffffff" }}>
            Loundry
          </Title>
        </div>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Row>
            <Col span={20}>
              <Title
                level={3}
                style={{ color: "#000000", marginBottom: "40px" }}
              >
                List Attendance Plan
              </Title>
            </Col>

            <Col>
              <Link to={PATH.ADDATTENDANCE} type="primary">
                <Button type="primary" htmlType="submit">
                  Tambah
                </Button>
              </Link>
            </Col>
          </Row>
          <Table dataSource={dataSource} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

// const dataSource = [
//   {
//     key: "1",
//     date: "2020-01-01",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "2",
//     date: "2020-01-02",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "3",
//     date: "2020-01-01",
//     in: "08:00",
//     out: "17:00",
//   },
//   {
//     key: "4",
//     date: "2020-01-02",
//     in: "08:00",
//     out: "17:00",
//   },
// ];

const columns = [
  {
    title: "No",
    dataIndex: "id",
    key: "name",
  },
  {
    title: "Name",
    dataIndex: "attendanceplanname",
    key: "age",
  },
  {
    title: "Period Start",
    dataIndex: "periodstart",
    key: "periodstart",
    render: (date) => returnTime(date), // render
  },
  {
    title: "Period End",
    dataIndex: "periodend",
    key: "periodend",
    render: (date) => returnTime(date), // render
  },
  {
    title: "attendance plan name",
    dataIndex: "attendanceplanname",
    key: "address",
  },
  {
    title: "Action",
    dataIndex: "",
    render: (_, record) => (
      <Space size="middle">
        <Link to={PATH.EDITATTENDANCE} state={record} type="primary">
          <Button type="primary">Edit</Button>
        </Link>
        <Link to={PATH.HISTORYATTENDANCE} state={record} type="primary">
          <Button type="primary">View</Button>
        </Link>
      </Space>
    ),
  },
];

export default Home;
